@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap');
* {
  user-select: none;
	color: unset;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html{
	font-size: 10px;
}
@keyframes slidein {
  from {
    opacity: 0;
		background-image: linear-gradient(transparent, transparent);
  }

  to {
    opacity: 1;
		background-image: linear-gradient(27deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%), linear-gradient(307deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%), linear-gradient(156deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
  }
}
body {
	line-height: 1;
	color: white;
	font-size: 1rem;
	background-image: linear-gradient(27deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%), linear-gradient(307deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%), linear-gradient(156deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
	font-family: 'Prompt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	animation: slidein .5s;
	position: relative;
	max-width: 100vw;
	overflow-x: hidden;
	min-height: 100vh;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}